<script setup lang="ts">
import { CardProps } from "./Card.props"
import { iconsSrcMap } from "./Card.iconMapping"

const props = defineProps<CardProps>()

const textArray = props.text.split("%link%")

const clickOnLink = (e: Event) => {
  let url = props.link
  if (props.linkQuery) {
    url += "?" + props.linkQuery
    if (props.sku) {
      url += "&sku=" + props.sku
    }
  } else if (props.sku) {
    url += "?sku=" + props.sku
  }
  navigateTo(url)
}
</script>

<template>
  <div class="banner-product_page-support-card">
    <div class="mb-2 flex items-center gap-2">
      <UtilsIcon v-if="iconType" v-bind="iconsSrcMap[iconType]" />
      <p :class="['beaver-bold', 'md:pig-bold', 'text-black-main', classTitle]">
        {{ title }}
      </p>
    </div>

    <p
      :class="[
        'markdown__link-underlined',
        'markdown__link-green-main beaver',
        'md:pig markdown__link-text-green-300',
        'markdown__link-hover-green-300',
        'text-black-80',
        classText
      ]"
    >
      <span v-for="(testo, index) in textArray">
        <UtilsMarkdown :content="testo" v-if="testo != ''" tag="span" />
        <button
          class="app-link btn-ghost-green cursor-pointer"
          v-if="index < textArray.length - 1 && link"
          @click="clickOnLink"
        >
          {{ linkText ? linkText : "scrivici" }}
        </button>
      </span>
    </p>
  </div>
</template>

<style>

.app-link {
}

</style>
